import React from "react";
import { Card, Table } from "react-bootstrap";

// components
import { useQuery } from "react-query";
import dayjs from "dayjs";
import { getSubscriptions } from "../../../helpers/api/subscription";
// import Table from "../../../components/Table/Table";
import { startCase } from "lodash";

const SubscriptionList = () => {
  const columns = [
    {
      Header: "Name",
      accessor: "product.name",
      sort: true,
    },
    {
      Header: "Status",
      accessor: "status",
      sort: true,
      Cell: ({ row }: { row: any }) => startCase(row.original.status),
    },
    {
      Header: "Price",
      accessor: "product.price_amount",
      sort: true,
      Cell: ({ row }: { row: any }) => (
        <>${row.original.product.price_amount}</>
      ),
    },
    {
      Header: "Subscription Date",
      accessor: "created_at",
      sort: true,
      Cell: ({ row }: { row: any }) =>
        dayjs(row.original?.created_at).format("DD MMM, YYYY"),
    },
    // {
    //   Header: "History",
    //   accessor: "action",
    //   sort: false,
    //   Cell: ({ row }: { row: any }) => (
    //     <>
    //       <Link
    //         to="#"
    //         className="action-icon"
    //         onClick={() => fetchHistory(row.original.id)}
    //       >
    //         <i className="mdi mdi-eye"></i>
    //       </Link>
    //     </>
    //   ),
    // },
  ];
  const { data: events } = useQuery({
    queryKey: ["subscriptions"],
    queryFn: () => getSubscriptions(),
  });

  return (
    <>
      <Card>
        <Card.Body>
          <h4 className="header-title mb-4">Paid Users List</h4>
          {/* <Table
            columns={columns}
            data={events?.data?.data || []}
            pageSize={10}
            isSortable={true}
            pagination={true}
            isSearchable={true}
            tableClass="table-nowrap table-hover"
            searchBoxClass="my-2"
          /> */}
          <Table
            striped
            bordered
            hover
            responsive
            style={{ minWidth: "600px" }}
          >
            <thead>
              <tr>
                <th style={{ color: "white", fontWeight: "600" }}>
                  Transaction ID
                </th>
                <th style={{ color: "white", fontWeight: "600" }}>Email</th>
                <th style={{ color: "white", fontWeight: "600" }}>Full Name</th>
                <th style={{ color: "white", fontWeight: "600" }}>Amount</th>
                <th style={{ color: "white", fontWeight: "600" }}>Status</th>
                <th style={{ color: "white", fontWeight: "600" }}>
                  Purchase Date
                </th>
              </tr>
            </thead>
            <tbody>
              {events?.data?.data.map((sub: any) => (
                <tr key={sub.id}>
                  <td style={{ color: "white" }}>
                    {sub.transaction_id.slice(0, 3) +
                      "****" +
                      sub.transaction_id.slice(-4)}
                  </td>
                  <td style={{ color: "white" }}>{sub?.user?.email}</td>
                  <td style={{ color: "white" }}>{sub?.user?.full_name}</td>
                  <td style={{ color: "white" }}>${sub.amount}</td>
                  <td>
                    <span
                      style={{
                        color: "#28a745",
                        fontWeight: "500",
                        textTransform: "uppercase",
                      }}
                    >
                      {sub.status}
                    </span>
                  </td>
                  <td style={{ color: "white" }}>
                    {new Date(
                      (sub.payload?.created ?? 0) * 1000
                    ).toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

export default SubscriptionList;
