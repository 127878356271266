import { APICore } from "./apiCore";

const api = new APICore();

export function getEvents() {
  const endpoint = "/admin/locations/all/events/";
  return api.get(`${endpoint}`, {});
}

export function getEventDetails(params: any) {
  const endpoint = `/properties/admin/events/${params.id}/`;
  return api.get(`${endpoint}`, {});
}

export function getAllBookings() {
  const endpoint = "/admin/bookings";

  return api.get(`${endpoint}`, {});
}

export function getBookingDetails(params: {
  id: number | string | null;
  date: string;
}) {
  if (!params.id) return null;
  const endpoint = `/admin/bookings/event-bookings/${params.id}/`;
  return api.get(`${endpoint}`, { date: params.date });
}
