import { APICore } from "./apiCore";

const api = new APICore();

export function getSubscriptions() {
  const endpoint = "/admin/payments/";
  return api.get(`${endpoint}`, {});
}

export function getProducts() {
  const endpoint = "/subscriptions/admin/products/";
  return api.get(`${endpoint}`, { page_size: 0 });
}

export function createProduct(data: any) {
  const endpoint = `/subscriptions/admin/products/`;
  return api.create(endpoint, data);
}
